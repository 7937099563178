@import '../../styles/index';

.base {
  overflow: visible;
  @include responsive-properties(border-radius);
}

.default {
  box-shadow: none;
}

.small {
  box-shadow: $shadow--small;
}

.medium {
  box-shadow: $shadow--medium;
}

.large {
  box-shadow: $shadow--large;
}
