@import '../../styles/index';

.base {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  max-width: 32rem;
  width: 100%;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  width: 100%;

  & > * {
    flex-grow: 1;
  }
}
