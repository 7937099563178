@import '../../styles/index';

.base {
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem;

  &.bottom-left {
    justify-content: flex-end;
  }

  &.top-left {
    justify-content: flex-start;
  }

  @include media-from(sm) {
    border-radius: 1rem;
    padding: 2rem;
  }
}
