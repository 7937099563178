@import '../../styles/index';

.base {
  height: 100vh;
  margin-top: -13.125rem;
  position: relative;

  @supports (height: 100dvh) {
    height: 100dvh;
  }
}

.background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bar {
  align-items: center;
  bottom: 3rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  left: 50%;
  max-width: 80rem;
  padding-inline: 1rem;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  z-index: 2;

  @include media-from(md) {
    padding-inline: 3rem;
  }

  &.with-title {
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;

    @include media-from(md) {
      align-items: flex-end;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }
  }
}

.title {
  color: $neutral-0;
  font-size: clamp(2rem, 6vw, 4.5rem);
  font-weight: 700;
  letter-spacing: -0.09rem;
  line-height: 1.11;
  margin: 0;
  max-width: 55rem;
  width: 100%;
}

.scroll-down {
  align-items: center;
  color: $neutral-0;
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 500;
  gap: 1rem;
  justify-content: center;
  line-height: 1.5;
  text-align: center;
}

.arrow {
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 99rem;
  color: $neutral-0;
  cursor: pointer;
  display: flex;
  display: flex;
  height: 3.25rem;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  width: 3.25rem;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
}

.bottom {
  bottom: 0;
  position: absolute;
}

.video {
  height: 100%;
  left: 0;
  object-fit: cover;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 2s ease-in-out;
  width: 100%;

  &[data-loaded] {
    opacity: 1;
    z-index: 1;
  }
}
