@import '../../styles/index';

.base {
  --hovered-track-height: 2.5rem;
  --initial-track-height: 1rem;
  --thumb-width: 2rem;
  --tooltip-gap: 0.25rem;
  --tooltip-height: 4.125rem;
  --tooltip-rotation: 0deg;
  --tooltip-tail-height: 0.7rem;
  --tooltip-tail-width: 1.0625rem;
  --track-border-radius: 100vw;

  padding-top: calc(
    var(--tooltip-height) + var(--tooltip-tail-height) + 0.75rem
  );
  position: relative;
  touch-action: none;
  transition: padding 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  user-select: none;

  &:has(.slider:hover) {
    --tooltip-gap: 0.75rem;
    --tooltip-rotation: var(--tooltip-current-rotation, -10deg);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-inline: 1rem;
  width: 100%;
}

.slider {
  align-items: center;
  display: flex;
  height: var(--hovered-track-height);
  padding-block: calc(
    (var(--hovered-track-height) - var(--initial-track-height)) / 2
  );
  position: relative;
  transition: padding 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  width: 100%;

  &:hover {
    padding-block: 0;

    .thumb {
      box-shadow:
        0px 5.333px 10.667px rgba(51, 51, 51, 0),
        0 0 0 0.25rem #b50971;
    }
  }
}

.track {
  background-color: var(--dark--aplha--5, rgba($neutral-12, 0.05));
  border-radius: var(--track-border-radius);
  box-shadow: 0px 4px 16px 0px rgba(161, 14, 75, 0.1) inset;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.range {
  background: linear-gradient(90deg, #d90b3b -0.98%, #b50971 100%),
    var(--accent--100, $surrogate-9);
  height: 100%;
  left: 0 !important;
  position: absolute;
  right: unset !important;
  width: var(--track-width);
}

.thumb {
  align-items: center;
  background-image: url(./dot.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: var(--radius--full, 50%);
  box-shadow: 0px 5.333px 10.667px rgba(51, 51, 51, 0.3);
  display: flex;
  height: 2rem;
  justify-content: center;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.7);
  width: 2rem;
  will-change: box-shadow;

  &:focus {
    box-shadow:
      0 1px 0.1875rem 0 rgba(var(--accent-rgb--100, 199, 18, 92), 0.12),
      0 0.5rem 1rem 0 rgba(var(--accent-rgb--100, 199, 18, 92), 0.12),
      0 0 0 0.25rem rgba(var(--accent-rgb--100, 199, 18, 92), 0.12),
      0px 5.333px 10.667px rgba(51, 51, 51, 0.3);
    outline: none;
  }
}

.tooltip-wrapper {
  left: var(--tooltip-offset);
  position: absolute;
  top: 0.5rem;
  transform: rotate(var(--tooltip-rotation))
    translate(calc(var(--thumb-width) / 2 * -1), calc(var(--tooltip-gap) * -1));
  transform-origin: center calc(100% + (var(--tooltip-tail-width) / 2));
  transition: transform 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  width: 9.1875rem;
  z-index: 2;

  @include media-from(md) {
    width: 9.9375rem;
  }
}

.tooltip {
  align-items: center;
  background: linear-gradient(90deg, #d90b3b -0.98%, #b50971 100%),
    var(--accent--100, $surrogate-9);
  border-radius: var(--radius--full, 100vw);
  color: $neutral-0;
  display: flex;
  flex-direction: column;
  height: var(--tooltip-height);
  justify-content: center;
  padding: 0.375rem 0.875rem;
  pointer-events: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  @include media-from(md) {
    --tooltip-height: 4.125rem;

    padding: 0.5rem 1.25rem;
  }

  .label {
    color: rgba($neutral-0, 0.8);
    font-size: $font-size-12;
    font-weight: 500;
  }

  .value {
    font-size: $font-size-24;
    font-weight: 500;
  }
}

.tail {
  aspect-ratio: 1;
  background: linear-gradient(90deg, #d90b3b -0.98%, #b50971 100%),
    var(--accent--100, $surrogate-9);
  background-attachment: fixed;
  background-position: left center;
  background-size: 9.9375rem;
  border-bottom-right-radius: 0.25rem;
  height: calc(100% + var(--tooltip-tail-height));
  mask-image: url('data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjY2IiBoZWlnaHQ9Ijc4IiB2aWV3Qm94PSIwIDAgNjYgNzgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik05LjY2NTQ4IDU2LjMzNDVDLTMuMjIxODMgNDMuNDQ3MiAtMy4yMjE4MyAyMi41NTI4IDkuNjY1NDggOS42NjU0OEMyMi41NTI4IC0zLjIyMTgzIDQzLjQ0NzIgLTMuMjIxODMgNTYuMzM0NSA5LjY2NTQ4QzY5LjIyMTggMjIuNTUyOCA2OS4yMjE4IDQzLjQ0NzIgNTYuMzM0NSA1Ni4zMzQ1TDM3LjI0MjYgNzUuNDI2NEMzNC44OTk1IDc3Ljc2OTYgMzEuMTAwNSA3Ny43Njk2IDI4Ljc1NzQgNzUuNDI2NEw5LjY2NTQ4IDU2LjMzNDVaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K');
  mask-position: var(--tail-position) top;
  mask-repeat: no-repeat;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.legend {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .marks {
    align-items: center;
    display: flex;
    height: 1rem;
    justify-content: space-between;
    padding-inline: 1rem;
    position: relative;

    .mark {
      background-color: var(--neutral--20, $neutral-7);
      border-radius: 100vw;
      display: flex;
      height: 0.5rem;
      transition:
        height 0.3s,
        background-color 0.3s;
      width: 0.0625rem;

      &:first-child,
      &:last-child {
        height: 1rem;
      }

      &.active {
        background-color: var(--neutral--100, $neutral-12);
        height: 1rem;
        width: 0.125rem;
      }
    }
  }

  .labels {
    color: var(--neutral--60, $neutral-9);
    display: flex;
    font-size: $font-size-12;
    font-weight: 400;
    justify-content: space-between;
    position: relative;

    > .label {
      display: block;
      transition:
        transform 0.3s,
        color 0.3s;
      white-space: nowrap;

      &.active {
        color: var(--neutral--100, $neutral-12);
        transform: scale(1.1);
      }
    }
  }
}
