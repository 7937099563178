@import '../../styles/index';

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.25rem 0;
  background-color: $neutral-0;
  box-shadow: $shadow-eng-map-filter-menu;
  border-radius: $border-radius-default;
}
