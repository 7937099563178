@import '../../styles/index';

.base {
  position: relative;
  width: 100%;
  max-width: $container-size-default;
  margin-inline: auto;
  scroll-snap-align: end;
}

.wrapper {
  position: relative;
  width: 100%;
}

.wrapper-inner {
  position: relative;
  display: flex;
  gap: 1.25rem;
  overflow-x: scroll;
  margin: 0 -1rem;
  padding: 2rem 0 2.5rem;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;

  @include media-from(sm) {
    padding: 1.5rem 0 3rem;
  }

  > * {
    flex: 0 0 18rem;
    scroll-snap-align: center;

    &:first-child {
      margin-left: 1.25rem;
    }

    &:last-child {
      margin-right: 1.25rem;
    }

    @include media-from(sm) {
      flex: 0 0 22.5rem;
    }
  }
}

.left-indicator,
.right-indicator {
  height: 100%;
  top: 0;
  pointer-events: none;
  position: absolute;
  width: 6rem;
  z-index: 20;
  opacity: 0;
  transition: opacity $duration-long;

  @include media-from(sm) {
    width: 10rem;
  }

  &.visible {
    opacity: 1;
  }
}

.left-indicator {
  background: linear-gradient(
    -90deg,
    rgba($neutral-0, 0) 0%,
    rgba($neutral-0, 0.5) 100%
  );
  left: -1rem;
}

.right-indicator {
  background: linear-gradient(
    90deg,
    rgba($neutral-0, 0) 0%,
    rgba($neutral-0, 0.5) 100%
  );
  right: -1rem;
}
