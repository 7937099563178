@import '../../../styles/index';

.base {
  width: 100%;

  & img {
    object-fit: cover;
  }
}

.image > div {
  border-radius: 0.5rem 0.5rem 0 0;
}

.bar {
  background: $gradient-enercity;

  //prevent ugly white bar
  margin-top: -1px;

  padding: 0.75rem 1.5rem;
  border-radius: 0 0 0.5rem 0.5rem;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  @include media-from(sm) {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

.download {
  color: $neutral-0;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.2;
}

.download-icon {
  margin-right: 0.5rem;
}

.shadow {
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.12);
}

.figcaption {
  margin-top: 0.75rem;
  text-align: left;
  width: 100%;
  color: $neutral-9;
  font-size: $font-size-14;
  line-height: 1.5;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;

  @include media-from(xs) {
    font-size: $font-size-16;
  }
}
