@import '../../styles/index';

.base {
  height: 100vh;
  margin: 0;
  max-height: 42rem;
  position: relative;
  width: 100%;
}

.map {
  height: 100%;
  width: 100%;
}

.detailsBoxWrapper {
  animation: box-apear $duration-default;
  background: $neutral-0;
  border-radius: $border-radius-large;
  bottom: 1.5rem;
  box-shadow:
    0px 0px 2px 0px rgba(0, 0, 0, 0.08),
    0px 8px 48px 0px rgba(0, 0, 0, 0.08);
  left: 1.5rem;
  margin: 0 auto;
  max-width: 25rem;
  position: absolute;
  right: 1.5rem;
  top: auto;
  width: minmax(calc(100% - 3rem), 25rem);

  @include media-from(xs) {
    bottom: auto;
    left: 1.5rem;
    right: auto;
    top: 1.5rem;
    width: 100%;
  }

  &.active {
    max-height: 1000px;
  }
}

.details-box {
  align-items: flex-start;
  animation: apear $duration-default $duration-default forwards;
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  font-weight: $font-weight-light;
  gap: 1.25rem;
  opacity: 0;
  padding: 1.25rem 1.25rem 1.5rem 1.25rem;
  user-select: none;
  width: 100%;

  p,
  .title {
    margin: 0;
  }
}

.image {
  aspect-ratio: 360 / 200;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.title {
  font-size: 1.25rem;
  font-weight: $font-weight-medium;
  line-height: 1.4;
}

.text-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
}

.link {
  align-items: center;
  color: $surrogate-9;
  display: flex;
  font-weight: $font-weight-medium;
  gap: 0.5rem;
  text-decoration: none;
}

@keyframes box-apear {
  from {
    opacity: 0;
    scale: 0.5;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes apear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
