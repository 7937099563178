@import '../../styles/index';

.base {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: $font-size-16;
  gap: 1.5rem;
  margin: 0 auto;
  max-width: 100%;
  width: 39rem;

  @include media-from(sm) {
    align-items: flex-start;
    font-size: $font-size-20;
  }
}

.bonus-box {
  align-items: center;
  align-self: stretch;
  background: var(--color-accent-solid-accent--5, #fcf3f7);
  border: 1px solid var(--color-accent-solid-accent--10, #fae8f0);
  border-radius: 0.5rem;
  display: flex;
  font-weight: $font-weight-medium;
  gap: 0.75rem;
  justify-content: space-between;
  line-height: 1;
  padding: 0.75rem 1rem;
}

.headline {
  display: block;
  font-size: $font-size-24;
  font-weight: $font-weight-medium;
  line-height: 1.125;
  margin: 0 0 0.5rem;
  text-align: center;
  width: 100%;

  @extend %text-linear-gradient;

  @include media-from(sm) {
    font-size: $font-size-30;
    line-height: 1;
  }
}

.data-items {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.data-item {
  align-items: last baseline;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: stretch;
  margin: 0;
  padding: 0;
  width: 100%;

  .dots {
    align-items: baseline;
    display: flex;
    flex: 1;
    font-size: 50%;
    font-weight: $font-weight-bold;
    min-width: 2.25ch;
    opacity: 0.3;
    overflow: hidden;

    &:before {
      content: '. . . . . . . . . . . . . . . . .'
        ' . . . . . . . . . . . . . . . . . . . .'
        ' . . . . . . . . . . . . . . . . . . . .'
        ' . . . . . . . . . . . . . . . . . . . .'
        ' . . . . . . . . . . . . . . . . . . . .';
      float: left;
      white-space: nowrap;
      width: 0;
    }
  }

  &-detail {
    align-items: baseline;
    display: flex;
    font-weight: $font-weight-medium;
    gap: 0.5rem;
    white-space: nowrap;
  }

  &.highlighted {
    color: $surrogate-9;
  }

  &-detail,
  &-label {
    margin: 0;
    .highlighted & {
      font-weight: $font-weight-bold;

      @extend %text-linear-gradient;
    }
  }
}

.button {
  margin-left: auto;

  @include media-from(sm) {
    margin-left: 0;
  }
}

.slider {
  margin-inline: 0;
  width: 100%;

  @include media-from(sm) {
    margin-inline: -1rem;
    width: calc(100% + 2rem);
  }
}

.switch {
  --toggle-size: 2rem;
}
