@import '../../styles/index';

.container {
  max-width: 800px;
  min-width: 250px;
  position: relative;
  width: 100%;

  @include media-from(xs) {
    margin-bottom: -6rem;
  }
}

.image-wrapper {
  @include media-from(xs) {
    aspect-ratio: 592 / 387;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2rem), 0% 100%);
    margin-right: 2rem;
    overflow: hidden;
    position: relative;
  }
}

.box {
  background-color: $neutral-0;
  border-radius: 0 0 $border-radius-default $border-radius-default;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.1),
    0 0 27px 0 rgba(0, 0, 0, 0.12);
  padding: 1rem;
  position: relative;

  @include media-from(xs) {
    left: 25%;
    position: relative;
    top: -6rem;
    width: 75%;
  }

  @include media-from(xs) {
    border-radius: $border-radius-default;
  }

  &.with-badge {
    border-top-right-radius: 0;
  }
}

.headline {
  display: block;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  line-height: 1.33;
  margin: 0;

  &:not(:last-child) {
    margin: 0 0 0.5rem;
  }

  @include media-from(xs) {
    font-size: $font-size-24;
  }
}

.description {
  font-size: $font-size-16;
  font-weight: $font-weight-light;
  line-height: 1.6;
  margin: 0 0 1rem;

  @include media-from(xs) {
    font-size: $font-size-20;
  }
}

.badge-container {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0, -100%);

  @include media-from(sm) {
    & > div {
      border-radius: 50% 50% 0 50% !important;
    }
  }
}
