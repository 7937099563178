@import '../../styles/index';

.container {
  width: 100%;
  height: 100%;
}

.map {
  position: absolute;
  width: 100%;
  z-index: $index-zero;
  height: calc(100% - 3.75rem);

  @include media-from(sm) {
    height: calc(100% - 5rem);
  }

  &.fullheight {
    height: 100%;
  }

  &.relative-position {
    position: relative;
  }

  &.with-border-radius > div {
    border-radius: $border-radius-default;

    // this is needed for google map to apply border-radius
    transform: translate3d(0px, 0px, 0px);
    mask-image: -webkit-radial-gradient(white, black);
  }
}
