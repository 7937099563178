@import '../../styles/index';

.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;

  @include media-from(sm) {
    padding-top: 2rem;
  }
}

.icon {
  color: $surrogate-9;
  height: 4rem;
  width: 4rem;
  margin-bottom: 0.5rem;

  @include media-from(sm) {
    margin-bottom: 1rem;
  }
}

.title {
  color: $neutral-12;
  font-size: 1.875rem;
  font-weight: $font-weight-medium;
  line-height: 1.25;
  text-align: center;
  margin: 0 0 0.5rem;
}

.title,
.text {
  max-width: 450px;
}

.text {
  color: $neutral-9;
  font-size: $font-size-20;
  text-align: center;
  margin: 0;
}
