@import '../../styles/index';

.subtitle {
  font-size: var(--scaled-font-size--1);
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.125rem;
  text-shadow:
    0 0 0.0625rem rgba(0, 0, 0, 0.08),
    0 0.5rem 2rem rgba(0, 0, 0, 0.08);

  @include media-from(md) {
    text-shadow:
      0 0 0.125rem rgba(0, 0, 0, 0.08),
      0 1rem 3rem rgba(0, 0, 0, 0.08);
  }
}

.title {
  font-size: var(--scaled-font-size-2);
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  text-shadow:
    0 0 0.0625rem rgba(0, 0, 0, 0.08),
    0 0.5rem 2rem rgba(0, 0, 0, 0.08);

  @include media-from(md) {
    text-shadow:
      0 0 0.125rem rgba(0, 0, 0, 0.08),
      0 1rem 3rem rgba(0, 0, 0, 0.08);
  }
}
