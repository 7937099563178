@import '../../styles/index';

.base {
  display: grid;
  gap: 1rem;

  &.is-decentral {
    grid-template-columns: 1fr;

    @include media-from(xs) {
      grid-auto-flow: row;
      grid-template-columns: 1fr 1fr;
    }

    @include media-from(md) {
      grid-auto-flow: column;
    }
  }

  &.is-district {
    grid-template-columns: 1fr;

    @include media-from(xs) {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include media-from(sm) {
    gap: 2rem;
  }
}

.arrow {
  @include media-from(sm) {
    bottom: 2rem;
    right: 2rem;
  }
}

.card {
  --card-inner-border-width: 0.5rem;

  border-radius: $border-radius-huge;
  box-shadow:
    inset 0 0 0 var(--card-inner-border-width) $neutral-0,
    0 0 0.125rem rgba(0, 0, 0, 0.08),
    0 1rem 3rem rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  isolation: isolate;
  min-height: 15rem;
  padding: 1.5rem;
  position: relative;
  transition: transform $duration-default;

  &.is-blue {
    aspect-ratio: 4 / 3;
    color: $blue-9;
    min-height: 0;
    padding: 0;
  }

  &.is-orange {
    background: linear-gradient(to right, rgba($orange-9, 0), rgba($orange-9, 0.1));
    color: $orange-9;

    @include media-from(xs) {
      grid-column: span 2;
    }

    @include media-from(md) {
      grid-column: unset;
    }
  }

  &.is-solid-blue {
    --card-inner-border-width: 0;
    aspect-ratio: 4 / 3;
    background:
      url('/assets/product-pages/heat/district-heating-map.svg'),
      linear-gradient(0deg, $blue-9, $blue-9);
    background-position: center bottom;
    background-size: cover;
    color: $neutral-0;
    min-height: 0;
  }

  &.is-turquoise {
    background: linear-gradient(to right, rgba($turquoise-9, 0), rgba($turquoise-9, 0.1));
    color: $turquoise-9;
  }

  &.is-violet {
    color: $violet-9;
    padding: 0;

    @include media-from(md) {
      grid-row: span 2;
    }
  }

  &:hover {
    transform: translateY(-0.25rem);
  }

  @include media-from(sm) {
    --card-inner-border-width: 0.75rem;

    padding: 2rem;
  }
}

.background-image {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}

.content {
  border-radius: inherit;
  box-shadow: inset 0 0 0 var(--card-inner-border-width) $neutral-0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 1.5rem;
  position: relative;

  @include media-from(sm) {
    padding: 2rem;
  }
}

.district-background {
  background-image: url('/assets/product-pages/heat/district-heating-illustration.svg');
  background-position: center bottom;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.icon {
  display: block;
  height: 4rem;
  margin-right: auto;
  margin-top: auto;
  width: auto;

  @include media-from(sm) {
    height: 6rem;
  }
}

.hover-provider,
.hover-provider > a {
  display: contents;
}

.subtitle,
.title {
  font-weight: 500;
  margin: 0;
}

.subtitle {
  font-size: $font-size-14;
  line-height: 1.3;
  text-shadow:
    0 0 0.0625rem rgba(0, 0, 0, 0.08),
    0 0.5rem 2rem rgba(0, 0, 0, 0.08);

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}

.title {
  font-size: $font-size-28;
  line-height: 1.2;
  margin-bottom: 4rem;
  text-shadow:
    0 0 0.0625rem rgba(0, 0, 0, 0.08),
    0 0.5rem 2rem rgba(0, 0, 0, 0.08);

  @include media-from(sm) {
    font-size: $font-size-44;
  }
}
