@import '../../styles/index';

.base {
  border: 0;
  border-radius: 5px;
  margin-bottom: 1rem;
  min-height: 34.75rem;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 768px) {
    min-height: 45rem;
  }
}
