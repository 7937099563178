@import '../../styles/index';

.base {
  position: relative;
  width: 100%;
  max-width: $container-size-default;
  margin-inline: auto;
  scroll-snap-align: end;
}

.wrapper {
  position: relative;
  width: 100%;

  &-inner {
    position: relative;
    display: flex;
    gap: 1.25rem;
    overflow-x: scroll;
    margin: 0 -1rem;
    padding: 2rem 0 2rem;
    position: relative;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-from(sm) {
      padding: 1.5rem 0;
    }
  }
}

.item {
  flex: 0 0 18rem;
  scroll-snap-align: center;

  &:first-child {
    padding-left: 1.25rem;
  }

  &:last-child {
    padding-right: 1.25rem;
  }

  & > button {
    height: 100%;
  }

  @include media-from(sm) {
    flex: 0 0 23.7rem;
  }
}
