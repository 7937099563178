@import '../../styles/index';

.base {
  z-index: $index-configurator-progress;
  height: 0;
  background: $gradient-enercity;
  transform-origin: left top;
  transition: transform 1s;
  height: 4px;
}

.with-background {
  height: 4px;
  background: $surrogate-3;
}
