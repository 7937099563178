@import '../../styles/index';

.base {
  position: relative;
  display: inline-block;

  background-color: $surrogate-2;
  overflow: hidden;

  @include responsive-properties(width height border-radius);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($surrogate-4, 0) 0,
      rgba($surrogate-4, 0.2) 20%,
      rgba($surrogate-4, 0.5) 60%,
      rgba($surrogate-4, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
