@import '../../styles/index';

.base {
  margin: 0;

  :global(.cmpvendorlist) {
    * {
      transition: all $duration-default;
      font-size: 14px;
      color: $neutral-12;
    }
  }

  :global(.cmpvendorlistitem) {
    border-top: 1px solid $surrogate-4;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: 1px solid $surrogate-4;
    }
  }

  :global(.cmpvendorlistheader) {
    background: none;
    transition: background $duration-default;
    font-size: $font-size-16;
    font-weight: $font-weight-medium;
    padding: 1rem;

    &:hover {
      background: $surrogate-2;
    }
  }

  :global(.cmpvendorlisticon) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global(.cmpvendordetails) {
    padding: 1rem;
    background: $surrogate-2;
    border-radius: 6px;
    margin-bottom: 1rem;

    a {
      color: $surrogate-9;
      text-decoration: underline !important;
      white-space: nowrap;
      font-weight: $font-weight-medium;
      position: relative;
    }

    ul {
      margin: 0.25rem 0;
      padding: 0 0 0 1.5rem;
      list-style: none;
      position: relative;

      &::before {
        content: '•';

        position: absolute;
        left: 0.5rem;
      }
    }
  }

  :global(.cmpvendorinfohl) {
    margin-top: 1rem;
    font-weight: $font-weight-medium;
  }

  :global(.cookieinfotable) {
    border-collapse: collapse;
    background-color: white;
    border-radius: 4px;
    margin-top: 1rem;
    max-width: 100%;
    display: inline-block;
    overflow-x: scroll;
    width: unset;

    thead {
      border-bottom: 1px solid $surrogate-4;
    }

    tr {
      &:first-child {
        td {
          padding: 1rem 1rem 0.25rem;
        }
      }

      &:last-child {
        td {
          padding: 0.25rem 1rem 1rem;
        }
      }
    }

    th,
    td {
      padding: 0.25rem 1rem;
      font-size: $font-size-14;
      text-align: left;
      white-space: nowrap;
      font-weight: $font-weight-light;
    }

    th {
      font-weight: $font-weight-medium;
      padding: 1rem;
    }
  }

  :global(.cmpvendorinfohl) {
    font-size: $font-size-14;
    line-height: 1.5;
  }

  :global(.cmpdatacategories) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
  }

  :global(.cmpdatacategory) {
    background: $neutral-0;
    color: $surrogate-7;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.25rem 0.75rem;
    line-height: 1.6;
    font-size: $font-size-12;
    border-radius: 6px;
    border: 1px solid $surrogate-4;
  }

  :global(.cmpvendordescr) {
    line-height: 1.4;
  }

  :global(.cmpboxtxt) {
    text-align: left;
    padding-bottom: 0;
  }
}
