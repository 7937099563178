@import '../../styles/index';

.contact-block {
  display: flex;
  font-size: 1rem;

  @include media-from(xs) {
    font-size: 1.5rem;
  }

  & p:not(:last-of-type) {
    margin-bottom: 0.25rem;
  }
}

.avatar {
  height: 128px;
  min-width: 128px;
  width: 128px;
}

.name {
  color: $neutral-12;
  display: block;
  font-size: 1.25rem;
  font-weight: $font-weight-medium;
  line-height: 1;

  @include media-to(sm) {
    font-size: $font-size-18;
  }

  em {
    color: $neutral-9;
    font-style: italic;
    font-weight: $font-weight-regular;
  }
}

.job-title {
  color: $neutral-12;
  display: block;
  font-size: 1.125rem;
  font-weight: $font-weight-light;
  line-height: 1;
  margin-top: 0.25rem;

  @include media-to(sm) {
    font-size: $font-size-16;
    margin-top: 0.125rem;
  }
}

.contactData {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 1.5rem;

  @include media-to(sm) {
    margin-left: 1rem;
    margin-top: 1.25rem;
  }

  @include media-to(xsbetween) {
    margin-left: 0;
    text-align: center;
  }
}

.links {
  color: $neutral-12;
  font-size: 1.125rem;
  line-height: 1;

  @include media-to(sm) {
    margin-top: 0.75rem;
  }

  .link {
    color: $surrogate-9;
    display: block;
    text-decoration: none;

    @include media-to(sm) {
      font-size: $font-size-16;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .link-mail {
    color: $surrogate-9;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
}

.phone {
  font-weight: $font-weight-medium;
}
