@import '../../styles/index';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.base {
  position: relative;

  height: 100%;
  width: 100%;
}

.image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
}

.blades {
  transform-origin: center;
  transform-box: fill-box;

  animation: rotate 1.25s linear infinite;
  animation-play-state: paused;
  animation-fill-mode: forwards;
}
