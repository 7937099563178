@import '../../styles/index';

.base {
  --toggle-size: 1.625rem;
  --toggle-icon-size: calc(var(--toggle-size) / 2);
  --toggle-width: calc(var(--toggle-size) * 1.75);
  --toggle-border-radius: calc(var(--toggle-size) / 2);
  --toggle-border-width: 0.125rem;
  --toggle-spacing: calc((var(--toggle-size) - var(--toggle-icon-size)) / 2);

  cursor: pointer;
  height: var(--toggle-size);
  position: relative;
  user-select: none;
  width: var(--toggle-width);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.switch,
.input {
  height: 100%;
  opacity: 0;
  width: 100%;
}

.slider {
  background-color: $neutral-0;
  border: var(--toggle-border-width) solid $surrogate-4;
  border-radius: var(--toggle-border-radius);
  bottom: 0;
  color: $surrogate-7;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition:
    background-color $duration-long,
    border-color $duration-long,
    transform $duration-long;

  &::before,
  &::after {
    border-radius: 50%;
    color: $surrogate-7;
    content: '';
    height: var(--toggle-icon-size);
    position: absolute;
    top: 50%;
    transition: 0.4s;
    translate: calc(var(--toggle-spacing) - var(--toggle-border-width)) -50%;
    width: var(--toggle-icon-size);
  }

  &::before {
    background-color: $surrogate-7;
    transition: 0.4s;
  }

  &::after {
    background-image: url('data:image/svg+xml, <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.3738 3.37622C12.7643 3.76675 12.7643 4.39991 12.3738 4.79044L6.54045 10.6238C6.35291 10.8113 6.09855 10.9167 5.83334 10.9167C5.56812 10.9167 5.31377 10.8113 5.12623 10.6238L2.20956 7.7071C1.81904 7.31658 1.81904 6.68341 2.20957 6.29289C2.60009 5.90236 3.23325 5.90236 3.62378 6.29289L5.83334 8.50245L10.9596 3.37622C11.3501 2.9857 11.9833 2.9857 12.3738 3.37622Z" fill="%23c7125c"/></svg>');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 85% 85%;
    opacity: 0;
    scale: 0.1;
    transition:
      opacity 0.2s,
      scale 0.2s,
      translate 0.4s;
  }
}

.input:checked + .slider {
  background-color: $surrogate-9;
  border-color: $surrogate-9;
  color: $surrogate-9;
}

.input:focus-visible {
  + .slider {
    box-shadow:
      0 1px 3px 0 rgba(199, 18, 92, 0.12),
      0 8px 16px 0 rgba(199, 18, 92, 0.12),
      0 0 0 4px rgba(199, 18, 92, 0.12);
  }
}

.input:checked + .slider {
  &::before,
  &:after {
    background-color: $neutral-0;
    translate: calc(
        var(--toggle-width) - var(--toggle-icon-size) - var(--toggle-spacing) - var(
            --toggle-border-width
          )
      ) -50%;
  }
  &::after {
    opacity: 1;
    scale: 1;
    transition:
      opacity 0.3s 0.2s,
      scale 0.3s 0.2s,
      translate 0.4s;
  }
}
