@import '../../styles/index';

.base {
  animation: scale-in $duration-default 1 forwards;
  transform-origin: bottom center;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  user-select: none;
  transform: translate(-50%, -100%);
  cursor: pointer;

  @include reduced-motion {
    animation: none;
  }

  & > svg,
  & > img {
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 50px;
    height: 50px;
    transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 1.3);
  }

  &:not(.active):hover {
    z-index: $index-map-marker;
  }
}

.dot {
  height: 1rem;
  width: 1rem;
  background-color: $neutral-0;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
}

.icon {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 18%;
  transform: translateX(-50%);
  color: $neutral-0;
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 1.3);
}

.iconActive {
  color: var(--icon-color, $surrogate-9);
  width: 32px;
  height: 32px;
}

.surrogate {
  color: $surrogate-9;
}

.yellow {
  color: $yellow-9;
}

.turquoise {
  color: $turquoise-9;
}

.violet {
  color: $violet-9;
}

.white {
  color: $neutral-0;
}

.blue {
  color: $blue-9;
}

.lightblue {
  --icon-color: $blue-7;
  color: $blue-7;
}

.green {
  color: $green-9;
}

.orange {
  color: $orange-9;
}

.gradient {
  & > svg path {
    fill: var(--gradient-fill-url, url(#linearGradient-enercity));
  }

  &.active {
    & > i svg path {
      fill: var(--gradient-fill-url, url(#linearGradient-enercity));
    }
  }
}

.small {
  & > svg,
  & > img {
    width: 2rem;
    height: 2rem;
  }

  .dot {
    width: 0.625rem;
    height: 0.625rem;
  }
}

.active {
  will-change: filter;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 0px 27px rgba(0, 0, 0, 0.12));
  z-index: $index-map-marker-active;

  & > svg,
  & > img {
    width: 5.625rem;
    height: 5.625rem;
  }

  & > svg path {
    fill: $neutral-0 !important;
  }

  .icon {
    color: currentColor;
    width: 2rem;
    height: 2rem;
  }
}

.with-animation {
  animation: bounce-pin 2s 1;
  animation-delay: 0.5s;
}

.pulse {
  background: $surrogate-3;
  border-radius: 50%;
  height: 0.875rem;
  width: 0.875rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 0.875rem;
  transform: rotateX(55deg) translate(-50%, -50%);
  z-index: -2;

  &::after {
    content: '';
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px $surrogate-9;
    animation-delay: 2.1s;
  }
}

@keyframes bounce-pin {
  0% {
    transform: translate(-50%, calc(-100% + -0.5rem));
  }
  100% {
    transform: translate(-50%, -100%);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
