@import '../../styles/index';

.base {
  position: relative;

  > div > div > label {
    display: flex;
    > input {
      margin: 0;
    }
  }
}

.input-box {
  position: relative;
}
.input {
  z-index: 1;

  & > label {
    background-color: transparent;

    > input {
      margin-top: 0.75rem;
    }
  }

  &.select-only > label {
    pointer-events: none;
  }
}

.input-suggestion {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  & input {
    margin-top: 0.5rem;
    opacity: 0.4 !important;
  }
}

.list {
  background: $neutral-0;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-block-end: 0;
  margin-block-start: 0;
  margin-bottom: 10rem;
  margin-top: 0.75rem;
  padding: 1rem;
  position: absolute;
  width: 100%;
  z-index: 3;

  &:not(.disable-native-select) {
    @include media-to(xs) {
      display: none;
    }
  }
}

.list-item {
  border-radius: $border-radius-small;
  color: $surrogate-9;
  font-size: $font-size-20;
  line-height: 1.4;
  list-style-type: none;
  padding: 0.5rem;
  user-select: none;

  @include media-from(sm) {
    padding: 1rem 0.5rem 1rem;
  }

  &:first-child {
    padding-top: 1rem;
  }

  &:last-child {
    padding-bottom: 1rem;
  }

  &[aria-selected='true'],
  &:hover {
    background: transparentize($color: $surrogate-9, $amount: 0.9);
    cursor: pointer;
  }
}

.native-select {
  -webkit-appearance: initial;
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
  z-index: 10;

  @include media-from(xs) {
    display: none;
  }

  &.select-only {
    width: 100%;
  }
}
