@import '../../styles/index';

$space-size: 0.75rem;

.base {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.telephone {
  font-size: $font-size-30;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;

  @include media-from(sm) {
    font-size: $font-size-44;
  }

  &,
  &-link {
    color: $surrogate-9;
  }
}

.description,
.title {
  color: $neutral-12;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  line-height: 1.5rem;

  @include media-from(sm) {
    font-size: $font-size-22;
  }
}

.title {
  margin: 0 auto 1.375rem;

  @include media-from(sm) {
    margin-top: 1rem;
  }
}

.description {
  color: $neutral-9;
  font-size: $font-size-20;
  font-weight: $font-weight-light;
  line-height: 1.4;
  margin: 0.75rem auto 0;

  @include media-from(sm) {
    font-size: $font-size-22;
    line-height: 2.25rem;
    margin-top: 1rem;
  }
}

.small {
  & .telephone {
    @include media-from(sm) {
      font-size: $font-size-30;
    }
  }

  & .description,
  & .title {
    @include media-from(sm) {
      font-size: $font-size-14;
    }
  }

  & .title {
    margin-bottom: 0.25rem;
  }

  & .description {
    margin-top: 0.25rem;
  }
}

.left {
  .description,
  .telephone,
  .title {
    text-align: left;
  }
}

.right {
  .description,
  .telephone,
  .title {
    text-align: right;
  }
}

.center {
  .description,
  .telephone,
  .title {
    text-align: center;
  }
}
