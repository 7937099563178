@import '../../styles/index';

.base {
  position: relative;
}

.map-container {
  position: relative;
  width: 100%;
  min-height: 85vh;
  overflow: hidden;
  margin-bottom: 1rem;

  @include media-from(sm) {
    min-height: 800px;
  }
}

.district-heat-map-legend {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 13.2rem;
  bottom: 1.5rem;
}

.district-heat-map-details {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 25.7rem;
  bottom: 1.5rem;
  padding: 0 1.5rem;
}

.search-box {
  position: relative;
  background-color: $neutral-0;
  border-radius: $border-radius-default;
  z-index: 1;

  & > div {
    box-shadow: $shadow-district-heat-map-search;
  }

  @include media-from(sm) {
    top: 1rem;
    left: 1rem;
    position: absolute;
  }
}

.full-screen {
  height: 100vh;
  width: 100vw;
  margin-bottom: 0;
}
