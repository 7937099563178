@import '../../styles/index';

.section-spacer {
  padding-block: 4rem !important;

  @include media-from(sm) {
    padding-block: 10rem !important;
  }
}

.question-container {
  width: 100%;
}

.jobs-headline {
  max-width: calc(100% - 2rem);
}
