@import '../../styles/index';

.base {
  appearance: none;
  background-color: $neutral-0;
  border: none;
  border-radius: $border-radius-large;
  box-shadow: $shadow--medium;
  color: inherit;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  overflow: hidden;
  padding: 0;
  text-align: left;
  transition: transform $duration-default;
  width: 100%;

  &.hoverTransition {
    &:focus,
    &:hover {
      transform: translateY(-4px);
    }
  }

  .image {
    overflow: hidden;
  }

  &.row {
    @include media-from(sm) {
      flex-direction: row;
      min-height: 25rem;

      .image {
        flex: 0 0 40%;
      }

      .content {
        flex-grow: 1;
        margin: auto 0;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;

    .headline {
      font-size: $font-size-22;
      font-weight: $font-weight-bold;
      line-height: 1.3;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: $font-size-16;
      font-weight: $font-weight-light;
      gap: 1rem;
      line-height: 1.5;
      margin: 0;

      & > * {
        margin: 0;
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      a {
        color: $surrogate-9;
        cursor: pointer;
        position: relative;
        top: 0;
        transform: scale3d(0, 0, 0);
        transition: text-decoration $duration-short ease-in-out;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      strong,
      b {
        font-weight: $font-weight-medium;
      }

      ul {
        @extend %list-checkmark;

        li:not(:last-of-type) {
          margin-bottom: 0.5rem;
        }

        li:last-child {
          margin-bottom: 0;
        }
      }
    }

    .footer {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    @include media-from(xs) {
      &:not(.max-size-s) {
        padding: 1.25rem 1.5rem;

        .headline:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        @include media-from(sm) {
          &:not(.max-size-m) {
            padding: 2rem;

            .headline {
              font-size: $font-size-24;
            }

            .text {
              font-size: $font-size-18;

              &:not(:last-child) {
                margin-bottom: 1.5rem;
              }
            }

            @include media-from(md) {
              &:not(.max-size-l) {
                padding: 3rem;

                .headline {
                  font-size: $font-size-30;
                }
              }
            }
          }
        }
      }
    }
  }
}
