@import '../../../styles/index';

.base {
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 1.375;
  white-space: nowrap;
  font-weight: $font-weight-medium;
  color: $violet-9;

  &::before {
    content: '';
    display: block;
    border-style: solid;
    border-width: 0.125rem;
    border-color: $violet-9;
    background-color: $violet-4;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}
