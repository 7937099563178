@import '../../styles/index';

.base {
  bottom: 1rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  width: 1.5rem;

  @include media-from(sm) {
    bottom: 1.5rem;
    height: 2.25rem;
    right: 1.5rem;
    width: 2.25rem;
  }
}
