@import '../../styles/index';

.base {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.shadow {
  border-radius: $border-radius-large;
  box-shadow: $shadow--medium;
  padding: 1rem;
}
