@import '../../styles/index';

.default {
  &.base {
    padding: 1.5rem;
    width: 100%;

    @include media-from(sm) {
      padding: 2rem;
    }
  }

  .description {
    line-height: 1.6;
    font-size: $font-size-18;
    margin-bottom: 1rem;
  }

  .title {
    line-height: 1.3;
    font-size: $font-size-22;
    margin-bottom: 1rem;

    @include media-from(sm) {
      font-size: $font-size-24;
      line-height: 1.3;
    }
  }
}

.small {
  &.base {
    width: 20.5rem;
    height: 20.5rem;
    padding: 1.875rem;

    svg {
      width: 3.125rem;
      height: 3.125rem;
    }

    button {
      font-size: 1.25rem;
    }
  }

  .description {
    line-height: 1.5;
    font-size: $font-size-18;
  }

  .title {
    line-height: 1.3;
    font-size: $font-size-24;
  }
}

.base {
  line-height: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 0 27px 0 rgba(0, 0, 0, 0.12);
  transition: background-color $duration-default,
    transform $duration-default ease-in-out,
    box-shadow $duration-default ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: $shadow-button-hover;
  }

  svg {
    display: block;
  }
}

.description {
  width: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
  user-select: none;
}

.title {
  width: 100%;
  user-select: none;
  font-weight: $font-weight-bold;
}

.simple {
  background-color: $neutral-0;
  color: $neutral-12;

  svg {
    color: $surrogate-9;
  }
}

.highlighted {
  &.base {
    background-color: $surrogate-9;
    color: $neutral-0;

    .top-container {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .button {
      margin: 0;
    }

    @include media-from(sm) {
      .title {
        line-height: 1.3;
        font-size: $font-size-24;
      }

      .description {
        line-height: 1.3;
        font-size: $font-size-24;
      }
    }
  }

  &.with-button {
    .top-container {
      padding-bottom: 1.5rem;
      height: calc(100% - 50px);
    }
  }

  svg {
    color: $neutral-0;
  }

  .title {
    width: 100%;
    user-select: none;
    font-weight: $font-weight-bold;
    text-align: center;
  }

  .description {
    width: 100%;
    margin-block-start: 0;
    margin-block-end: 0;
    user-select: none;
    text-align: center;
    align-self: center;
    color: $neutral-0;
    font-weight: $font-weight-bold;
    font-size: $font-size-22;
  }
}

.badge-xxs {
  margin: 0 auto;
  top: -1rem;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  right: 0;
}

.badge-xxs-inner {
  @include media-from(sm) {
    display: none;
  }
}

.badge-sm {
  display: none;
  position: absolute;
  transform: translateY(-6rem);
  right: 2rem;
  z-index: $index-teaser-tile-badge;

  @include media-from(sm) {
    display: block;
  }
}

.button {
  margin-top: 0.5rem;
}

.button-simple {
  width: 100%;
  display: flex;
}

.top-container {
  width: 100%;
}

.clickable {
  display: flex;

  &:focus {
    outline: none;
  }
}

.icon {
  margin-bottom: 2rem;
}
