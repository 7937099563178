@import '../../styles/index';

.list {
  list-style-type: none;
  padding-inline-start: 0;
}

.padding {
  @include media-from(sm) {
    padding-inline-start: 40px;
  }
}

.item {
  font-size: 1rem;
  line-height: 1.25rem;
  list-style-type: none;
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
  transition: color $duration-default;

  @include media-from(sm) {
    font-size: $font-size-20;
    line-height: 2rem;
  }

  & > i {
    position: absolute;
    left: 0;
    top: 5px;
  }

  &--checkmark {
    & > i {
      color: $turquoise-9;
    }
  }

  &--close {
    & > i {
      color: $surrogate-9;
    }
  }

  &__inactive {
    color: $neutral-9;
  }
}

.fontSizeRegular {
  font-size: $font-size-18;
}
