@import '../../styles/index';

.base {
  background-color: $neutral-0;
  border: 2px solid $neutral-7;
  border-radius: $border-radius-default;
  width: 100%;

  > label {
    background-color: transparent;
    border: none;

    > span {
      top: 0.2rem;
    }
  }
}

.amount {
  max-height: 0;
  transition: max-height $duration-default, opacity $duration-default;
  opacity: 0;
  color: $neutral-12;
  font-size: $font-size-24;
  font-weight: $font-weight-medium;
  line-height: 1.25;
  margin: 1.5rem 0 1rem;

  &.visible {
    max-height: 30px;
    opacity: 1;
  }
}
