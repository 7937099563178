@import '../../styles/index';

.base {
  max-width: 100%;
  overflow-x: scroll;

  table {
    border-collapse: collapse;
  }

  thead {
    border-bottom: 1px solid $surrogate-4;
  }

  th,
  td {
    padding: 1rem;
    font-size: $font-size-14;
    text-align: left;
    font-weight: $font-weight-light;
  }

  th {
    font-weight: $font-weight-medium;
  }

  tbody > tr {
    &:nth-child(even) {
      background-color: $surrogate-2;
    }
  }

  a {
    color: $surrogate-9;
    text-decoration: underline !important;
    white-space: nowrap;
    font-weight: $font-weight-medium;
  }
}
