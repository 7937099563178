@import '../../styles/index';

.description {
  font-size: $font-size-16;
  line-height: 1.5;

  @include media-from(md) {
    font-size: $font-size-18;
    line-height: 1.6;
  }
}
