@import '../../styles/index';

.base {
  align-items: flex-start;
  background: $surrogate-1;
  border: 1px solid $surrogate-3;
  border-radius: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin-inline: auto;
  max-width: 68rem;
  padding: 2rem;

  @media (min-width: 68rem) {
    gap: 4rem;
    padding: 3rem;
  }
}

.content {
  display: flex;
  flex: 1 1 30rem;
  flex-direction: column;
}

.title {
  font-size: $font-size-36;
  font-weight: $font-weight-medium;
  letter-spacing: -0.045rem;
  line-height: 1.333;

  @extend %text-linear-gradient;
}

.description {
  color: $neutral-12;
  font-size: $font-size-22;
  font-weight: $font-weight-light;
  line-height: 1.5;
  margin-top: 1rem;
}
.thumbnail-wrapper {
  display: flex;
}

.thumbnail {
  align-items: center;
  aspect-ratio: 1 / 1;
  background: $neutral-0;
  border: 1px solid $surrogate-3;
  border-radius: 1rem;
  display: flex;
  flex: 1 1 auto;
  gap: 0.5rem;
  justify-content: center;
  max-width: 16rem;
  padding: 2rem;

  img {
    max-width: 100%;
    object-fit: contain;
  }
}

.children {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}
