@import '../../../styles/magazine-index';

.base {
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-basis: 100%;
  padding: 0 0 1.25rem;

  @include media-from(md) {
    padding: 1.125rem;
    flex-basis: calc(100% / 3);
  }

  @include media-from(lg) {
    padding: 2rem;
  }
}

.wrapper {
  border-radius: $border-radius-large;
  padding: 3rem 1.5rem;
  color: $neutral-0;
  position: relative;
  height: 100%;
  width: 100%;

  @include setMagazineCategoryColorFor('background-color');

  @include media-from(md) {
    height: 590px;
    padding: 80px 40px;
  }
}

.content {
  position: relative;
  z-index: 2;
}

.title {
  font-size: $font-size-48;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
  max-width: 200px;

  @include media-from(md) {
    font-size: $font-size-64;
    margin-bottom: 1.5rem;
    max-width: 100%;
  }
}

.text {
  font-size: $font-size-18;
  line-height: 1.6;
  font-weight: $font-weight-medium;
  width: 100%;
  margin-bottom: 1rem;

  @include media-from(sm) {
    max-width: 20.625rem;
  }

  @include media-from(md) {
    font-size: $font-size-20;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    max-width: 13rem;
  }
}

.background-image {
  display: none;
  z-index: 1;
  overflow: hidden;

  @include media-from(sm) {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 18rem;
    top: 0;
    right: 0;
  }

  @include media-from(md) {
    max-width: none;
  }
}

.image-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}
